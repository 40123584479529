import { getVideoAiClipUploadable, getVideoUploadable } from '@api/shoplive/video';
import { UPLOAD_MEDIA_MAX_FILE_SIZE } from '@common/helper/file';
import { t } from '@common/helper/languages';
import { useAuthorityState } from '@context/authority/AuthorityContext';
import { useBoolean } from '@hooks/useBoolean';
import { useMessage } from '@hooks/useMessage';
import { useConfirm } from '@hooks/useModalConfirm';
import { TVideoUploadable } from '@localTypes/video';
import { FileData, TAiClipFileData, useVideoUploadStore } from '@store/videoStore';
import { IconButton } from '@styles/design-system/button/IconButton';
import { Text } from '@styles/design-system/text/Text';
import { isEmpty, isNumber, isString } from 'lodash';
import { useEffect, useMemo, useRef } from 'react';
import { MultiUploadItem } from './MultiUploadItem';
import { StyledMultiUploadWrapper } from './styled';

function getRequestUrl({
  videoUploadable,
  aiClipFileData,
}: {
  videoUploadable: TVideoUploadable;
  aiClipFileData?: TAiClipFileData;
}) {
  const { uploadApiEndpoint, sessionSecret } = videoUploadable;

  const url = new URL(uploadApiEndpoint);

  url.searchParams.set('sessionSecret', sessionSecret);

  if (aiClipFileData) {
    url.searchParams.set('aiClipTitle', aiClipFileData.title);
    url.searchParams.set('aiClipDescription', aiClipFileData.description);
    url.searchParams.set('aiClipLanguage', aiClipFileData.language);
    if (isNumber(aiClipFileData.numOfClips)) {
      url.searchParams.set('aiClipNumOfClips', `${aiClipFileData.numOfClips}`);
    }
    url.searchParams.set('aiClipClipLength', `${aiClipFileData.clipLength}`);
    if (isString(aiClipFileData.sttVersion)) {
      url.searchParams.set('aiClipSttVersion', aiClipFileData.sttVersion);
    }
    if (!isEmpty(aiClipFileData.resourceCategoryIds)) {
      url.searchParams.set('resourceCategoryIds', aiClipFileData.resourceCategoryIds.join(','));
    }
  }

  return url.toString();
}

function categorizeVideoUploadList(videoUploadList: FileData[]) {
  const totalCount = videoUploadList.length;

  const loadingCount = videoUploadList.filter(({ status }) => status === 'LOADING').length;
  const successCount = videoUploadList.filter(({ status }) => status === 'SUCCESS').length;
  const failCount = videoUploadList.filter(({ status }) => status === 'FAIL').length;

  return {
    isAllLoading: totalCount === loadingCount,
    isAllSuccess: totalCount === successCount,
    isAllFail: totalCount === failCount,
    loadingCount,
    successCount,
    failCount,
    totalCount,
  } as const;
}

export const MultiUpload = () => {
  const { bool: isExpanded, setTrue: onIsExpanded, toggleBoolean: toggleIsExpanded } = useBoolean(true);

  const { language } = useAuthorityState();
  const { modalConfirm } = useConfirm();
  const { customMessage } = useMessage();

  const {
    videoUploadList,
    uploadQueue,
    allClearUploadData,
    setIndividualUploadProgress,
    setIndividualUploadStatus,
    deleteVideoList,
    setUploadVideoList,
  } = useVideoUploadStore();
  const { emitAiClipBulkFile } = useVideoUploadStore();

  // 각 파일 업로드에 대한 xhr 객체 추적용
  const xhrs = useRef(new Map()).current;

  // 새로고침할때, 업로드중인 영상이 있으면, 경고
  useEffect(() => {
    const handleBeforeunload = (e: any) => {
      const { loadingCount } = categorizeVideoUploadList(videoUploadList);

      if (loadingCount > 0) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    /**
     * 다른 코드에서 <a> tag를 통해 파일 다운로드를 시도하는 경우에도 beforeunload 이벤트가 발생하여 브라우저 Alert가 표시됨.
     * 다른 코드에서 다운로드 시도하는 동안 window.onbeforeunload 함수를 비워두고 다운로드 완료시 window.onbeforeunload를 설정.
     * (addEventListener를 사용하면 안되는 이유)
     *
     */
    window.onbeforeunload = handleBeforeunload;
    return () => {
      window.onbeforeunload = null;
    };
  }, [videoUploadList]);

  // 축소된 채로, 업로드 컴포넌트가 unvisible된다면 다시 확대 시키자
  useEffect(() => {
    if (isEmpty(videoUploadList) && !isExpanded) {
      onIsExpanded();
    }
  }, [videoUploadList, isExpanded]);

  useEffect(() => {
    // 큐에 파일이 있으면 업로드 로직 실행
    if (!isEmpty(uploadQueue)) {
      uploadStart();
    }
  }, [uploadQueue]);

  useEffect(() => {
    // 업로드 취소했을때, videoUploadList가 사라지면 xhrs를 초기화 해준다.
    if (isEmpty(videoUploadList) && !isEmpty(xhrs)) {
      xhrs.clear();
    }
  }, [xhrs, videoUploadList]);

  const handleUploadError = (errorData: any) => {
    const errorMessage = typeof errorData === 'string' ? errorData : 'ERROR : ' + JSON.parse(errorData);
    const content = errorMessage || 'Failed to upload';
    customMessage('error', {
      content,
    });
  };

  const handleUploadSuccess = (result: any, aiClipFileData?: TAiClipFileData) => {
    customMessage('success', {
      content: t('page.media.upload.status_success'),
    });
    setUploadVideoList(JSON.parse(result));

    if (aiClipFileData) {
      emitAiClipBulkFile();
    }
  };

  const uploadStart = async () => {
    // 항상 최신 값을 유지하기 위해 내부에서 state 관리
    const { uploadQueue, isUploadLoading, dequeueUpload, setIsUploadLoading } = useVideoUploadStore.getState();
    // 하나 업로드중이면 다음 영상 업로드 일시 정지
    if (isUploadLoading) return;

    const uploadItem = uploadQueue[0];
    // 큐가 빈배열일때 작업을 멈춰주자
    if (!uploadItem) return;

    try {
      const getVideoUploadableRequest = uploadItem.aiClipFileData ? getVideoAiClipUploadable : getVideoUploadable;
      const videoUploadable = await getVideoUploadableRequest();
      uploadItem.requestUrl = getRequestUrl({ videoUploadable, aiClipFileData: uploadItem.aiClipFileData });
    } catch (error) {
      console.error('uploadable error:', error);
      // 에러 발생 시 해당 파일을 큐에서 제거하고 다음 파일로 이동
      dequeueUpload();
      setTimeout(uploadStart, 0);
      return;
    }

    const { file, requestUrl, aiClipFileData } = uploadItem;

    if (!file) return; // 큐 작업이 실패하면 업로드 하지 않는다.

    if (file.size > UPLOAD_MEDIA_MAX_FILE_SIZE) {
      return handleUploadError(t('error.exceed_file_size'));
    }

    setIsUploadLoading(true);

    const xhr = new XMLHttpRequest();
    xhrs.set(file.uid, xhr);
    xhr.upload.onprogress = (e) => {
      if (e.lengthComputable) {
        // 개별 업로드 진행률 계산
        const rate = Math.floor((e.loaded / e.total) * 100);
        setIndividualUploadProgress(file.uid, rate);
      } else {
        // 측정 불가
        console.warn('Can not length computable');
      }
    };

    xhr.onloadend = async (e) => {
      try {
        const json = xhr.responseText;
        //업로드 취소해도 이 로직이 실행되서, 성공할때만 handleUploadSuccess 로직을 실행시킨다.
        if (xhr.status === 200) {
          setIndividualUploadStatus(file.uid, 'SUCCESS');
          handleUploadSuccess(json, aiClipFileData);
        } else {
          setIndividualUploadStatus(file.uid, 'FAIL');
          customMessage('error', {
            content: t('page.media.upload_all_fail'),
          });
        }
        // 성공하면 업로드 된 항목을 큐에서 제거
        dequeueUpload();
        setIsUploadLoading(false);
      } catch (xhr: any) {
        handleUploadError(xhr);
      }
    };

    const formData = new FormData();

    formData.append('video', file);
    // TODO 나중에 미디어 썸네일도 등록할 수 있도록 추가
    // formData.append('image', image);
    xhr.open('POST', requestUrl);
    xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('accessToken')}`);
    xhr.send(formData);
  };

  const onClickCancelUpload = (item: FileData) => {
    const xhr = xhrs.get(item.uid);
    if (!xhr) return;
    const deleteVideoListXhr = () => {
      xhr.abort();
      xhrs.delete(item.uid);
      deleteVideoList(item);
    };

    if (item.status === 'LOADING') {
      modalConfirm(t('page.media.upload_cancel_text'), {
        onOk() {
          deleteVideoListXhr();
          customMessage('error', {
            content: t('page.media.upload_cancel_complete'),
          });
        },
      });
    } else {
      deleteVideoListXhr();
      customMessage('success', {
        content: t('page.media.upload.remove_success'),
      });
    }
  };

  const onClickAllClearUploadList = () => {
    const { loadingCount } = categorizeVideoUploadList(videoUploadList);

    if (loadingCount > 0) {
      modalConfirm(t('page.media.upload_stop_upload_content'), {
        title: t('page.media.upload_stop_upload_title'),
        onOk() {
          if (!isEmpty(xhrs)) {
            xhrs.forEach((xhr, uid) => {
              xhr.abort();
              xhrs.delete(uid);
            });
            allClearUploadData();
          }
        },
      });
    } else {
      allClearUploadData();
    }
  };

  const uploadStatusMessage = useMemo(() => {
    const { isAllFail, isAllSuccess } = categorizeVideoUploadList(videoUploadList);

    return isAllFail
      ? t('page.media.upload_all_fail')
      : isAllSuccess
      ? t('page.media.upload_success')
      : t('page.media_list.list_item_upload_ing');
  }, [videoUploadList]);

  const uploadingMessage = useMemo(() => {
    const determineParamOrder = (lang: string, uploadCount: number, totalCount: number) => {
      switch (lang) {
        case 'en':
          return [uploadCount, totalCount];
        default:
          return [totalCount, uploadCount];
      }
    };

    const { totalCount, successCount } = categorizeVideoUploadList(videoUploadList);
    const [firstParam, secondParam] = determineParamOrder(language, successCount, totalCount);

    return t('page.media.upload_total', firstParam, secondParam);
  }, [language, videoUploadList]);

  if (isEmpty(videoUploadList)) {
    return null;
  }

  const { failCount } = categorizeVideoUploadList(videoUploadList);

  return (
    <StyledMultiUploadWrapper isExpanded={isExpanded}>
      <div className="title-wrapper">
        <div className="upload-text-wrapper">
          <Text style={{ fontWeight: 600 }} variant="BodyM" color="Black700">
            {uploadStatusMessage}
          </Text>

          <Text variant="CaptionM" color="Black500">
            {uploadingMessage}
            {failCount > 0 && (
              <>
                <span>,</span>
                <Text style={{ marginLeft: 2 }} span variant="CaptionM" color="AlertNegative">
                  {t('page.media.upload_fail', failCount)}
                </Text>
              </>
            )}
          </Text>
        </div>

        <div className="button-wrapper">
          <IconButton
            icon={isExpanded ? 'arrowDownSLine' : 'arrowUpSLine'}
            variant="basic"
            size="medium"
            onClick={toggleIsExpanded}
          />
          <IconButton icon="closeLine" variant="basic" size="medium" onClick={onClickAllClearUploadList} />
        </div>
      </div>

      <div className="item-container">
        {videoUploadList.map((item, idx: number) => (
          <MultiUploadItem key={idx} item={item} onClickCancelUpload={onClickCancelUpload} />
        ))}
      </div>
    </StyledMultiUploadWrapper>
  );
};
