import { getLanguageResource } from '@api/shoplive/admin';
import 'moment/locale/ko';
import 'moment/locale/ja';
import moment from 'moment';

export type TLanguageCode = 'en' | 'ko' | 'ja';

const KEY_CURRENT_LANGUAGE = 'crrntlanguage';
const DEFAULT_LANGUAGE = 'en';
const LANG_CODE_FOR_KEY_CHECK = 'keyCheck';

const getResourceLocalKey = (languageCode: string) => {
  return `lngsrc_` + languageCode;
};

const supportLangs = ['en', 'ko', 'ja'];

export const initLanguageCode = (lang: string) => {
  const code = supportLangs.includes(lang) ? lang : DEFAULT_LANGUAGE;
  localStorage.setItem(KEY_CURRENT_LANGUAGE, code);
};

class LanguageManagerClass {
  currentResource: any = null;
  currentLanguage = '';

  reloadCurrentLanguageResource() {
    // 저장되어있는 랭귀지 코드를 가져온다.
    const currentLang = localStorage.getItem(KEY_CURRENT_LANGUAGE);
    if (currentLang) {
      this.currentLanguage = currentLang;
    } else {
      // 네비게이터에서 랭귀지를 가져와서, 서포트 하는 언어가 아니면 'en' 이다.
      const naviLang = navigator.language?.substring(0, 2);
      this.currentLanguage = supportLangs.includes(naviLang) ? naviLang : DEFAULT_LANGUAGE;
    }

    // moment 기본 시간변경.
    try {
      moment.locale(this.currentLanguage);
    } catch (error) {
      console.error(error);
    }

    const key = getResourceLocalKey(this.currentLanguage);
    const resource = localStorage.getItem(key);
    this.currentResource = resource ? JSON.parse(resource) : null;
  }

  // 서버에서 최신의 랭귀지 리소스 가져오기.
  setCurrentLanguageAndRefresh(language: string | null = null) {
    const lang = language ?? this.currentLanguage;
    return new Promise<string>((resolve, reject) => {
      getLanguageResource(lang)
        .then((data) => {
          this.currentLanguage = lang;
          this.currentResource = data;
          localStorage.setItem(KEY_CURRENT_LANGUAGE, lang);
          localStorage.setItem(getResourceLocalKey(lang), JSON.stringify(data));

          if (language === LANG_CODE_FOR_KEY_CHECK) {
            this.currentResource = {};
            localStorage.setItem(getResourceLocalKey(lang), JSON.stringify({}));
          }
          try {
            moment.locale(this.currentLanguage);
          } catch (error) {
            console.error(error);
          }

          resolve(this.currentLanguage);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  }
}

const languageManager = new LanguageManagerClass();

const t = (key: string, ...args: any[]) => {
  const rsc = languageManager.currentResource;

  if (!rsc) {
    return key;
  }

  let str: string = rsc[key];
  if (str === null || str === undefined) {
    return key;
  }
  for (const arg of args) {
    str = str.replace('{}', arg);
  }
  return str;
};

const isKo = () => {
  return languageManager.currentLanguage === 'ko';
};

export { languageManager, t, isKo, LANG_CODE_FOR_KEY_CHECK };
