import styled from 'styled-components';
import Select, { OptionProps } from 'antd/lib/select';

type Props = { value?: any; label?: string };
function CustomOption({ value, label, children, ...rest }: OptionProps & Props) {
  return <S_Option {...rest}>{children}</S_Option>;
}

export default CustomOption;

const S_Option = styled(Select.Option)``;
