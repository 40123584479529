import React, { useCallback, useMemo } from 'react';
import { TSeller } from 'types/seller';
import { t } from '@common/helper/languages';
import { Avatar, Dropdown, MenuProps } from 'antd';
import RegionSelector from './region-selector';
import CustomerSelector from './customer-selector';
import SellerSelectBox from '@components/seller/seller-select-box';
import { changeCurrentSeller, deleteCurrentSeller } from '@api/shoplive/sellers';
import { StyledTopHeader, StyledAccountName, StyledWrapAccountMenu } from './styled';
import { ReactComponent as LogoutIcon } from '@assets/svg/Line__Systems--logout-box-line.svg';
import { ReactComponent as AccountIcon } from '@assets/svg/Line--Systems--settings-3-line.svg';
import { ReactComponent as UserIcon } from '@assets/svg/user-outline-icon.svg';
import { useNavigate } from 'react-router-dom';
import { useAuthorityState } from '@context/authority/AuthorityContext';
import { UserSwitchOutlined } from '@ant-design/icons';
// import { ReactComponent as LanguageIcon } from '@assets/svg/sidemenu-language.svg';
// import { ReactComponent as ArrowRightIcon } from '@assets/svg/Line__Systems--arrow-right-s-line.svg';

import LanguageDropdownMenu from '@components/sidemenu/language-dropdown-menu';
import { SESSION_KEY_ADMIN_ORIGIN_JWT, SESSION_KEY_ADMIN_ORIGIN_NAME, saveToken } from '@common/helper/auth';
import { Text } from '@styles/design-system/text/Text';

interface IMainHeaderProps {
  setIsShowDrawer: (value: boolean) => void;
}

const MainHeader: React.FC<IMainHeaderProps> = ({ setIsShowDrawer }) => {
  const navigate = useNavigate();

  const { administrator, myPrivileges, isGlobalSuperAdmin, isEbayHost, language } = useAuthorityState();

  const selectedSellerId = (administrator as any)?.currentSeller?.sellerId ?? null;

  const handleAccountMenu = useCallback(({ key }: any) => {
    if (key === 'settings') {
      navigate('/user/settings');
    }
    if (key === 'logout') {
      try {
        sessionStorage?.clear();
        localStorage?.clear();
      } catch (error) {
        console.error(error);
      }

      //ebayHost 이면,
      if (isEbayHost) {
        const logoutWindow = window.open('https://www.ebay.com/logout');
        setTimeout(() => {
          logoutWindow?.close();
          navigate('/login');
        }, 1000);
      } else {
        navigate('/login');
      }
    }
  }, []);

  const showDrawer = () => {
    setIsShowDrawer(true);
  };

  const onSellerSelected = (seller: TSeller | null) => {
    // 셀러를 선택하거나 취소할때, 요청 API 가 다르다.
    const promise = seller ? changeCurrentSeller(seller.sellerId) : deleteCurrentSeller();
    promise
      .then(() => {
        window.location.href = '/';
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const items = useMemo(() => {
    const itemsArr: MenuProps['items'] = [
      {
        key: 'settings',
        label: (
          <div className="custom-dropdown-menu" style={{ whiteSpace: 'nowrap' }}>
            <AccountIcon />
            {t('menu.account.settings')}
          </div>
        ),
        onClick: handleAccountMenu,
      },
      {
        key: 'lang',
        label: <LanguageDropdownMenu className="lang" />,
      },
      {
        type: 'divider',
      },
      {
        key: 'logout',
        label: (
          <div className="custom-dropdown-menu">
            <LogoutIcon />
            {t('menu.account.logout')}
          </div>
        ),
        onClick: handleAccountMenu,
      },
    ];

    const restoreJwt = sessionStorage.getItem(SESSION_KEY_ADMIN_ORIGIN_JWT);
    const restoreName = sessionStorage.getItem(SESSION_KEY_ADMIN_ORIGIN_NAME);

    if (restoreJwt && restoreName) {
      itemsArr.push({
        key: 'restore',
        label: (
          <div className="custom-dropdown-menu">
            <UserSwitchOutlined />
            {restoreName}
          </div>
        ),
        onClick: () => {
          saveToken(restoreJwt, null, null);
          sessionStorage.removeItem(SESSION_KEY_ADMIN_ORIGIN_JWT);
          location.reload();
        },
      });
    }

    return itemsArr;
  }, [administrator, language]);

  return (
    <StyledTopHeader>
      <StyledWrapAccountMenu>
        <img
          src="https://resource.shoplive.cloud/images/admin/svg/sidemenu-list.svg"
          className="drawer-button"
          onClick={showDrawer}
        />

        <div className="inner-wrapper">
          {isGlobalSuperAdmin && <RegionSelector />}

          <CustomerSelector />

          {myPrivileges.isSellerList && (
            <SellerSelectBox
              sellerNoneTitle={t('page.seller_selector.all_sellers')}
              selectedSellerId={selectedSellerId}
              onSellerSelected={onSellerSelected}
              isRevalidateOnClick={true}
            />
          )}
          <Dropdown
            menu={{ items }}
            trigger={['click']}
            onOpenChange={handleAccountMenu}
            overlayClassName="campaign-list-dropdown"
          >
            <StyledAccountName>
              <Avatar size={36} icon={<UserIcon style={{ width: 36, height: 36 }} />} />
              <Text variant="BodyM" color="Black" className="name">
                {administrator?.name || 'Anonymous'}
              </Text>
            </StyledAccountName>
          </Dropdown>
        </div>
      </StyledWrapAccountMenu>
    </StyledTopHeader>
  );
};

export default MainHeader;
