import { css, keyframes, styled } from 'styled-components';
import { Icon } from '@styles/design-system/icon/Icon';
import { Text } from '@styles/design-system/text/Text';

const LoadingPage = () => {
  return (
    <StyledHost>
      <Icon style={{ marginBottom: 16 }} icon={'loader4Fill'} color="Black700" size={32} />
      <Text style={{ textAlign: 'center', whiteSpace: 'pre-line' }} variant="BodyM" color="Black500">
        LOADING...
      </Text>
    </StyledHost>
  );
};
export default LoadingPage;

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const spinCss = css`
  svg {
    animation: ${rotate} 1s linear infinite;
  }
`;

const StyledHost = styled.div`
  height: 100%;
  min-height: 419px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${spinCss}
`;
