import { getCampaignAuthority } from '@api/shoplive/admin';
import { useMemo } from 'react';

const getFunctionsObject = (functions: string[] | null | undefined): IMyFunctions => {
  return {
    QUIZ: functions?.includes('QUIZ') || false,
    CAMPAIGN_ADMINISTRATOR: functions?.includes('CAMPAIGN_ADMINISTRATOR') || false,
    GOODS_URL_IMPORT: functions?.includes('GOODS_URL_IMPORT') || false,
    MEDIA: functions?.includes('MEDIA') || false,
    GOODS_SHOW_ON_READY: functions?.includes('GOODS_SHOW_ON_READY') || false,
    VIDEO_LANDSCAPE: functions?.includes('VIDEO_LANDSCAPE') || false,
    PRODUCT_COUPON_BANNER: functions?.includes('PRODUCT_COUPON_BANNER') || false,
    CUSTOM_LOTTIE: functions?.includes('CUSTOM_LOTTIE') || false,
    AUCTION: functions?.includes('AUCTION') || false,
    PREVIEW_WITH_LOGIN: functions?.includes('PREVIEW_WITH_LOGIN') || false,
    BRAND_IDENTIFIER: functions?.includes('BRAND_IDENTIFIER') || false,
    PRODUCT_SCHEMES: functions?.includes('PRODUCT_SCHEMES') || false,
    END_OF_STREAM: functions?.includes('END_OF_STREAM') || false,
    REDIRECT_CAMPAIGN: functions?.includes('REDIRECT_CAMPAIGN') || false,
    BRAND_FAVORITE: functions?.includes('BRAND_FAVORITE') || false,
    POPUP_TEXT_IMAGES: functions?.includes('POPUP_TEXT_IMAGES') || false,
    SHORT_FORM: functions?.includes('SHORT_FORM') || false,
    SHORT_FORM_MENU: functions?.includes('SHORT_FORM_MENU') || false,
    EBAY_AUCTION: functions?.includes('EBAY_AUCTION') || false,
    JAPANESE: functions?.includes('JAPANESE') || false,
    AUCTION_BLOCK: functions?.includes('AUCTION_BLOCK') || false,
    GOODS_CTA_BUTTON_TITLE: functions?.includes('GOODS_CTA_BUTTON_TITLE') || false,
    GOODS_CTA_BUTTON_TITLE_DROPDOWN: functions?.includes('GOODS_CTA_BUTTON_TITLE_DROPDOWN') || false,
    CAMPAIGN_BOOKMARK: functions?.includes('CAMPAIGN_BOOKMARK') || false,
    POPUP_FAQ: functions?.includes('POPUP_FAQ') || false,
    STREET_11: functions?.includes('STREET_11') || false,
    SHORTFORM_DATAINSIGHT: functions?.includes('SHORTFORM_DATAINSIGHT') || false,
    SHORTFORM_REVIEW: functions?.includes('SHORTFORM_REVIEW') || false,
    SHORTFORM_GROUP: functions?.includes('SHORTFORM_GROUP') || false,
    SHORTFORM_CREATOR: functions?.includes('SHORTFORM_CREATOR') || false,
    CONSOLE_HIGHLIGHT_ON_CLICK_CHAT: functions?.includes('CONSOLE_HIGHLIGHT_ON_CLICK_CHAT') || false,
    LIVE_REPLY: functions?.includes('LIVE_REPLY') || false,
    LIVE_INDIVIDUAL_CHAT_MESSAGE_COLOR: functions?.includes('LIVE_INDIVIDUAL_CHAT_MESSAGE_COLOR') || false,
    AI_CLIP: functions?.includes('AI_CLIP') || false,
    AI_CLIP_TOP_BOTTOM_MASKING: functions?.includes('AI_CLIP_TOP_BOTTOM_MASKING') || false,
    AI_CLIP_CAP_SUBTITLE_SEGMENT_LENGTH: functions?.includes('AI_CLIP_CAP_SUBTITLE_SEGMENT_LENGTH') || false,
    ARCHIVE_STREAM: functions?.includes('ARCHIVE_STREAM') || false,
    CONSOLE_USER_BLOCK_ON_CLICK_NAME: functions?.includes('CONSOLE_USER_BLOCK_ON_CLICK_NAME') || false,
    DISABLE_ADVANCED_PRODUCT_SORT: functions?.includes('DISABLE_ADVANCED_PRODUCT_SORT') || false,
    SELLER_SCHEMES: functions?.includes('SELLER_SCHEMES') || false,
    CONSOLE_CHAT_FREEZE: functions?.includes('CONSOLE_CHAT_FREEZE') || false,
    EBAY_ADJUST_PRICE: functions?.includes('EBAY_ADJUST_PRICE') || false,
    LIVE_DATA_INSIGHT_V2: functions?.includes('LIVE_DATA_INSIGHT_V2') || false,
    CAMPAIGN_PRODUCT_AUTO_SYNC: functions?.includes('CAMPAIGN_PRODUCT_AUTO_SYNC') || false,
    CAMPAIGN_PRODUCT_IMPORT: functions?.includes('CAMPAIGN_PRODUCT_IMPORT') || false,
    PURCHASE_VERIFICATION: functions?.includes('PURCHASE_VERIFICATION') || false,
    REPLAY_HIGHLIGHT_CLIP: functions?.includes('REPLAY_HIGHLIGHT_CLIP') || false,
    CONSOLE_LEGACY_CHAT_ITEM: functions?.includes('CONSOLE_LEGACY_CHAT_ITEM') || false,
    DISABLE_MUTE_ON_CONSOLE_CHAT_DELETE: functions?.includes('DISABLE_MUTE_ON_CONSOLE_CHAT_DELETE') || false,
    AI_CLIP_CATEGORY: functions?.includes('AI_CLIP_CATEGORY') || false,
    USE_EBAY_PRODUCT: functions?.includes('USE_EBAY_PRODUCT') || false,
    USE_PRODUCT_SUB_PRICE: functions?.includes('USE_PRODUCT_SUB_PRICE') || false,
    DATA_USAGE_DASHBOARD: functions?.includes('DATA_USAGE_DASHBOARD') || false,
  };
};

export interface IMyFunctions {
  QUIZ?: boolean;
  CAMPAIGN_ADMINISTRATOR?: boolean;
  GOODS_URL_IMPORT?: boolean;
  MEDIA?: boolean;
  GOODS_SHOW_ON_READY?: boolean;
  VIDEO_LANDSCAPE?: boolean;
  PRODUCT_COUPON_BANNER?: boolean;
  CUSTOM_LOTTIE?: boolean;
  AUCTION?: boolean;
  PREVIEW_WITH_LOGIN?: boolean;
  BRAND_IDENTIFIER?: boolean;
  PRODUCT_SCHEMES?: boolean;
  END_OF_STREAM?: boolean;
  REDIRECT_CAMPAIGN?: boolean;
  BRAND_FAVORITE?: boolean;
  POPUP_TEXT_IMAGES?: boolean;
  SHORT_FORM?: boolean;
  SHORT_FORM_MENU?: boolean;
  EBAY_AUCTION?: boolean;
  JAPANESE?: boolean;
  AUCTION_BLOCK?: boolean;
  GOODS_CTA_BUTTON_TITLE?: boolean;
  GOODS_CTA_BUTTON_TITLE_DROPDOWN?: boolean;
  CAMPAIGN_BOOKMARK?: boolean;
  POPUP_FAQ?: boolean;
  STREET_11?: boolean;
  SHORTFORM_DATAINSIGHT?: boolean;
  SHORTFORM_REVIEW?: boolean;
  SHORTFORM_GROUP?: boolean;
  SHORTFORM_CREATOR?: boolean;
  CONSOLE_HIGHLIGHT_ON_CLICK_CHAT?: boolean;
  LIVE_REPLY?: boolean;
  LIVE_INDIVIDUAL_CHAT_MESSAGE_COLOR?: boolean;
  AI_CLIP?: boolean;
  AI_CLIP_TOP_BOTTOM_MASKING?: boolean;
  AI_CLIP_CAP_SUBTITLE_SEGMENT_LENGTH?: boolean;
  ARCHIVE_STREAM?: boolean;
  CONSOLE_USER_BLOCK_ON_CLICK_NAME?: boolean;
  DISABLE_ADVANCED_PRODUCT_SORT?: boolean;
  SELLER_SCHEMES?: boolean;
  CONSOLE_CHAT_FREEZE?: boolean;
  EBAY_ADJUST_PRICE?: boolean;
  LIVE_DATA_INSIGHT_V2: boolean;
  CAMPAIGN_PRODUCT_AUTO_SYNC?: boolean; // campaignId 에 속해있는 상품들을 Sync 해주는 함수 호출할 수 있는 권한.
  CAMPAIGN_PRODUCT_IMPORT?: boolean; // dealId 로 고객사 API 로 상품 끌어와서 캠페인에 등록해주는 권한
  PURCHASE_VERIFICATION?: boolean; //수동 구매 인증
  REPLAY_HIGHLIGHT_CLIP?: boolean; //하이라이트 클립
  CONSOLE_LEGACY_CHAT_ITEM?: boolean;
  DISABLE_MUTE_ON_CONSOLE_CHAT_DELETE?: boolean;
  AI_CLIP_CATEGORY?: boolean; // AI Clip 카테고리 기능 (Resource Category API 사용)
  USE_EBAY_PRODUCT?: boolean;
  USE_PRODUCT_SUB_PRICE?: boolean;
  DATA_USAGE_DASHBOARD?: boolean;
}

const getPrivilegesObject = (privileges: string[] | null | undefined): IMyPrivileges => {
  return {
    isShortformSnsPublish: privileges?.includes('SHORTFORM_SNS_PUBLISH') || false,
    isConsoleManageCampaign: privileges?.includes('CONSOLE_MANAGE_CAMPAIGN') || false,
    isConsoleManageLive: privileges?.includes('CONSOLE_MANAGE_LIVE') || false,
    isConsoleManageChat: privileges?.includes('CONSOLE_MANAGE_CHAT') || false,
    isConsoleRead: privileges?.includes('CONSOLE_READ') || false,
    isCampaignList: privileges?.includes('CAMPAIGN_LIST') || false,
    isCustomerList: privileges?.includes('CUSTOMER_LIST') || false,
    isCustomerRead: privileges?.includes('CUSTOMER_READ') || false,
    isCustomerManage: privileges?.includes('CUSTOMER_MANAGE') || false,
    isCustomerCreate: privileges?.includes('CUSTOMER_CREATE') || false,
    isAdministratorList: privileges?.includes('ADMINISTRATOR_LIST') || false,
    isAdministratorRead: privileges?.includes('ADMINISTRATOR_READ') || false,
    isAdministratorManage: privileges?.includes('ADMINISTRATOR_MANAGE') || false,
    isHostList: privileges?.includes('HOST_LIST') || false,
    isHostManage: privileges?.includes('HOST_MANAGE') || false,
    isBannedWordList: privileges?.includes('BANNED_WORD_LIST') || false,
    isBannedWordManage: privileges?.includes('BANNED_WORD_MANAGE') || false,
    isCustomerBannedWordList: privileges?.includes('CUSTOMER_BANNED_WORD_LIST') || false,
    isCustomerBannedWordManage: privileges?.includes('CUSTOMER_BANNED_WORD_MANAGE') || false,
    isHostBannedWordList: privileges?.includes('HOST_BANNED_WORD_LIST') || false,
    isHostBannedWordManage: privileges?.includes('HOST_BANNED_WORD_MANAGE') || false,
    isCampaignRead: privileges?.includes('CAMPAIGN_READ') || false,
    isCampaignManage: privileges?.includes('CAMPAIGN_MANAGE') || false,
    isCampaignCreateDelete: privileges?.includes('CAMPAIGN_CREATE_DELETE') || false,
    isCampaignReset: privileges?.includes('CAMPAIGN_RESET') || false,
    isGoodsList: privileges?.includes('GOODS_LIST') || false,
    isGoodsRead: privileges?.includes('GOODS_READ') || false,
    isGoodsManage: privileges?.includes('GOODS_MANAGE') || false,
    isPopupList: privileges?.includes('POPUP_LIST') || false,
    isPopupRead: privileges?.includes('POPUP_READ') || false,
    isPopupManage: privileges?.includes('POPUP_MANAGE') || false,
    isPrompterRead: privileges?.includes('PROMPTER_READ') || false,
    isPrompterManage: privileges?.includes('PROMPTER_MANAGE') || false,
    isDataInsightRead: privileges?.includes('DATA_INSIGHT_READ') || false,
    isAdministratorManualPassword: privileges?.includes('ADMINISTRATOR_MANUAL_PASSWORD') || false,
    isGhostManage: privileges?.includes('GHOST_MANAGE') || false,
    isSellerList: privileges?.includes('SELLER_LIST') || false,
    isSellerManage: privileges?.includes('SELLER_MANAGE') || false,
    isConsoleManageBannedWords: privileges?.includes('CONSOLE_MANAGE_BANNED_WORDS') || false,
    isSystemBannedWordManage: privileges?.includes('SYSTEM_BANNED_WORD_MANAGE') || false,
    isShortformList: privileges?.includes('SHORTFORM_LIST') || false,
    isLoading: privileges === undefined || privileges === null,
    isShortformManage: privileges?.includes('SHORTFORM_MANAGE') || false, // 숏폼 검수자 only는 해당 권한 x
  };
};

export interface IMyPrivileges {
  isLoading?: boolean;
  isConsoleManageCampaign?: boolean;
  isConsoleManageLive?: boolean;
  isConsoleManageChat?: boolean;
  isConsoleRead?: boolean;
  isCustomerList?: boolean;
  isCustomerRead?: boolean;
  isCustomerManage?: boolean;
  isCustomerCreate?: boolean;
  isAdministratorList?: boolean;
  isAdministratorRead?: boolean;
  isAdministratorManage?: boolean;
  isHostList?: boolean;
  isHostManage?: boolean;
  isBannedWordList?: boolean;
  isBannedWordManage?: boolean;
  isCustomerBannedWordList?: boolean;
  isCustomerBannedWordManage?: boolean;
  isHostBannedWordList?: boolean;
  isHostBannedWordManage?: boolean;
  isCampaignList?: boolean;
  isCampaignRead?: boolean;
  isCampaignManage?: boolean;
  isCampaignCreateDelete?: boolean;
  isCampaignReset?: boolean;
  isGoodsList?: boolean;
  isGoodsRead?: boolean;
  isGoodsManage?: boolean;
  isPopupList?: boolean;
  isPopupRead?: boolean;
  isPopupManage?: boolean;
  isPrompterRead?: boolean;
  isPrompterManage?: boolean;
  isDataInsightRead?: boolean;
  isGhostManage?: boolean;
  isAdministratorManualPassword?: boolean;
  isSellerList?: boolean;
  isSellerManage?: boolean;
  isConsoleManageBannedWords?: boolean;
  isSystemBannedWordManage?: boolean;
  isShortformSnsPublish?: boolean;
  isShortformList?: boolean;
  isShortformManage?: boolean;
}

const useCampaignPrivileges = (campaignId?: string) => {
  const { data: authority, error: campaignAuthorityError } = getCampaignAuthority(campaignId);
  const privileges = useMemo(
    () => getPrivilegesObject(campaignAuthorityError ? [] : authority?.privileges),
    [authority, campaignAuthorityError],
  );
  return privileges;
};

export { getPrivilegesObject, useCampaignPrivileges, getFunctionsObject };
