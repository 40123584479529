export enum Param {
  PAGE = '_p',
  LIMIT = '_l',
  SORT_COLUMN = '_sc',
  SORT_ASCENDING = '_sa',
  SEARCH_KEYWORD = '_k',
  FROM = 'from',
  TO = 'to',
  BOOKMARK = 'bookmark',
  HAS_RUNNING_TIME = 'hasRunningTime',
  VIEW_TYPE = 'viewType',
  SHOW_PRODUCT_COUNT = 'showProductCount',
  /**
   * 카테고리 (필터 용도)
   * - 적용범위는 enum ResourceCategoryType 참고
   */
  RESOURCE_CATEGORY_IDS = 'resourceCategoryIds',
}
