import RegionManager from '@common/regions';
import CustomSelect from '@styles/design-system/select/CustomSelect';
import { useTheme } from 'styled-components';
import { useWindowResize } from '@hooks/useWindowResize';
import CustomOption from '@styles/design-system/select/CustomOption';

const RegionSelector: React.FC = () => {
  const theme = useTheme();
  const [width] = useWindowResize();
  if (RegionManager.availableRegions.length <= 1) {
    return null;
  }

  return (
    <CustomSelect
      style={{
        minWidth: width >= theme.innerWidth.tablet ? 160 : 60,
      }}
      value={RegionManager.apiEndpoint}
      onChange={(val) => {
        RegionManager.saveApiEndpoint(val);
        location.href = '#/';
      }}
    >
      {RegionManager.availableRegions.map((region) => (
        <CustomOption key={region.adminApiUrl} value={region.adminApiUrl} style={{}}>
          {region.title}
        </CustomOption>
      ))}
    </CustomSelect>
  );
};

export default RegionSelector;
