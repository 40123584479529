import { sellersBriefListGet } from '@api/shoplive/sellers';
import { t } from '@common/helper/languages';
import { getThumbnail, PLACEHOLDER_IMAGE } from '@common/helper/thumbnail';
import { TSeller } from '@localTypes/seller';
import { useEffect, useRef } from 'react';
import CustomSelect from '@styles/design-system/select/CustomSelect';
import { useWindowResize } from '@hooks/useWindowResize';
import { useTheme } from 'styled-components';
import CustomOption from '@styles/design-system/select/CustomOption';

interface ISellerSelectBoxProps {
  sellerNoneTitle?: string;
  onSellerSelected: (seller: TSeller | null) => void;
  selectedSellerId: string | null;
  disabled?: boolean;
  isRevalidateOnClick?: boolean;
}

const SELLER_NONE_VALUE = 'NONE';

const SellerSelectBox: React.FC<ISellerSelectBoxProps> = ({
  sellerNoneTitle = t('page.seller_selector.none'),
  onSellerSelected,
  selectedSellerId,
  disabled,
  isRevalidateOnClick,
}) => {
  const { data, mutate: revalidate } = sellersBriefListGet();
  const [width] = useWindowResize();
  const theme = useTheme();

  const refSelect = useRef(null);

  useEffect(() => {
    const dom = document.querySelector('.seller-select-box input.ant-select-selection-search-input');
    dom?.setAttribute('autocomplete', 'one-time-code');
  }, [refSelect]);

  const sellers = data?.results || [];

  const filterOption = (input: string, option: any) => {
    if (option?.value === SELLER_NONE_VALUE) {
      return sellerNoneTitle.toLowerCase().includes(input.toLowerCase());
    }
    // sellers 배열에서 현재 옵션의 value에 해당하는 seller 찾기
    const correspondingSeller = sellers.find((seller) => seller.sellerId === option?.value);

    // 해당 seller의 name이 사용자의 입력값을 포함하는지 검사
    return correspondingSeller?.name.toLowerCase().includes(input.toLowerCase()) || false;
  };

  return (
    <CustomSelect
      style={{
        width: width >= theme.innerWidth.tablet ? 160 : 80,
        display: width < theme.innerWidth.mobile_small ? 'none' : 'inline-block',
      }}
      className="seller-select-box"
      showSearch
      value={selectedSellerId || SELLER_NONE_VALUE}
      disabled={disabled}
      filterOption={filterOption}
      ref={refSelect}
      onChange={(value) => {
        const sellerObject = sellers.find((item) => item.sellerId === value);
        onSellerSelected(sellerObject || null);
      }}
      onClick={(e) => {
        if (isRevalidateOnClick) {
          revalidate();
        }
      }}
    >
      <CustomOption value={SELLER_NONE_VALUE}>{sellerNoneTitle}</CustomOption>
      {sellers.map((item) => (
        <CustomOption value={item.sellerId} key={item.sellerId}>
          <img
            src={getThumbnail(item.profileUrl || PLACEHOLDER_IMAGE, 240)}
            style={{
              width: 24,
              height: 24,
              borderRadius: 12,
              objectFit: 'cover',
              marginRight: 4,
            }}
          />
          <span>{item.name}</span>
        </CustomOption>
      ))}
    </CustomSelect>
  );
};

export default SellerSelectBox;
