import { Collapse, CollapsePanelProps, CollapseProps } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { Icon } from '../icon/Icon';

type Props = {
  defaultActiveKey: string[];
  panelProps: CollapsePanelProps;
  panelChildren: React.ReactNode;
};
function CustomCollapse({ defaultActiveKey, panelProps, panelChildren, ...rest }: Props & CollapseProps) {
  return (
    <S_Collapse
      ghost={true}
      defaultActiveKey={defaultActiveKey}
      expandIconPosition={rest?.expandIconPosition ?? 'end'}
      expandIcon={
        rest?.expandIcon
          ? rest.expandIcon
          : ({ isActive }) => {
              return isActive ? <Icon icon="arrowUpSLine" size={20} /> : <Icon icon="arrowDownSLine" size={20} />;
            }
      }
      {...rest}
    >
      <Collapse.Panel {...panelProps}>{panelChildren}</Collapse.Panel>
    </S_Collapse>
  );
}

export default CustomCollapse;

const S_Collapse = styled(Collapse)`
  padding: 0;
  .ant-collapse-header {
    padding: 0 !important;
  }
  .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding-top: 0;
    padding-bottom: 0;
    padding: 0;
    > div {
      padding-left: 40px;
    }
  }
`;
