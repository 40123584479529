import { TVideo } from '@localTypes/video';
import { create } from 'zustand';
import { RcFile } from 'antd/lib/upload';
import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';
import { TCreateAiClipBulk } from '@localTypes/ai-clip';
import { MediaTab } from '@components/video/video.enum';

type VideoState = {
  selectedVideo: TVideo | null;
  setSelectedVideo: (video: TVideo | null) => void;
  selectedCampaignVideo: any;
  setSelectCampaignVideo: (selectedCampaignVideo: any) => void;
  currentVideoTab: MediaTab;
  setCurrentVideoTab: (currentVideoTab: MediaTab) => void;
  videoPageQuery: number;
  setVideoPageQuery: (videoPageQuery: number) => void;
  campaignPageQuery: number;
  setCampaignPageQuery: (campaignPageQuery: number) => void;
  searchKeyword: string;
  setSearchKeyword: (searchKeyword: string) => void;
  modalStep: number;
  setModalStep: (modalStep: number) => void;
  videoListModalState: 'HIDDEN' | 'LIST_MODE' | 'LIST_FULLSCREEN_MODE' | 'EDIT_MODE';
  setVideoListModalState: (videoListModalState: 'HIDDEN' | 'LIST_MODE' | 'LIST_FULLSCREEN_MODE' | 'EDIT_MODE') => void;
};

/**
 * AI Cilp Bulk 생성 > 영상 업로드한 경우,
 * 서버에서 영상 업로드 완료후 곧바로 AI Clip을 생성하기 위한 값.
 */
export type TAiClipFileData = {
  title: TCreateAiClipBulk['title'];
  description: TCreateAiClipBulk['description'];
  language: TCreateAiClipBulk['language'];
  numOfClips: TCreateAiClipBulk['numOfClips'];
  clipLength: TCreateAiClipBulk['clipLength'];
  sttVersion: TCreateAiClipBulk['sttVersion'];
  resourceCategoryIds: TCreateAiClipBulk['resourceCategoryIds'];
};

export interface FileData {
  uid: string;
  name: string;
  size: number;
  rate: number;
  type: string;
  status: 'FAIL' | 'SUCCESS' | 'LOADING';
  file: RcFile;
  requestUrl: string;
  aiClipFileData?: TAiClipFileData;
}

interface VideoUploadState {
  uploadSuccessAt: number;
  uploadSuccessVideoList: TVideo[];
  videoUploadList: FileData[];
  uploadQueue: FileData[];
  isUploadLoading: boolean;
  actionRequestURL: string;
  /**
   * AI Clip Bulk 영상 업로드가 완료되었을때 콜백을 실행하기 위함.
   */
  aiClipBulkFileEmitter: number;
  emitAiClipBulkFile: () => void;
  setIndividualUploadProgress: (fileId: string, percent: number) => void;
  setIndividualUploadStatus: (fileId: string, status: 'FAIL' | 'SUCCESS' | 'LOADING') => void;
  setUploadVideoList: (video: TVideo) => void;
  enqueueUpload: (file: FileData) => void;
  dequeueUpload: () => void;
  setIsUploadLoading: (value: boolean) => void;
  allClearUploadData: () => void;
  deleteVideoList: (file: FileData) => void;
}

export const useVideoStore = createWithEqualityFn<VideoState>(
  (set) => ({
    modalStep: 1,
    setModalStep: (modalStep: number) =>
      set(() => ({
        modalStep,
      })),
    searchKeyword: '',
    setSearchKeyword: (searchKeyword: string) =>
      set(() => ({
        searchKeyword,
      })),
    videoPageQuery: 1,
    setVideoPageQuery: (videoPageQuery: number) =>
      set(() => ({
        videoPageQuery: videoPageQuery,
      })),
    campaignPageQuery: 1,
    setCampaignPageQuery: (campaignPageQuery: number) =>
      set(() => ({
        campaignPageQuery,
      })),
    currentVideoTab: MediaTab.VIDEO,
    setCurrentVideoTab: (currentVideoTab: MediaTab) =>
      set(() => ({
        currentVideoTab,
      })),
    selectedCampaignVideo: null,
    setSelectCampaignVideo: (selectedCampaignVideo: any) =>
      set(() => ({
        selectedCampaignVideo,
      })),
    selectedVideo: null,
    setSelectedVideo: (video: TVideo | null) => set(() => ({ selectedVideo: video })),
    videoListModalState: 'LIST_MODE',
    setVideoListModalState: (videoListModalState: 'HIDDEN' | 'LIST_MODE' | 'LIST_FULLSCREEN_MODE' | 'EDIT_MODE') =>
      set(() => ({ videoListModalState })),
  }),
  shallow,
);

export const useVideoUploadStore = create<VideoUploadState>((set, get) => ({
  uploadSuccessAt: 0,
  uploadSuccessVideoList: [],
  videoUploadList: [],
  isUploadLoading: false,
  uploadQueue: [],
  actionRequestURL: '',
  aiClipBulkFileEmitter: 0,
  emitAiClipBulkFile: () => {
    set(() => ({ aiClipBulkFileEmitter: get().aiClipBulkFileEmitter + 1 }));
  },
  enqueueUpload: (file) =>
    set((state) => {
      const newUploadQueue = [...state.uploadQueue, file];
      const newVideoUploadList = [...state.videoUploadList, file];
      return { uploadQueue: newUploadQueue, videoUploadList: newVideoUploadList };
    }),
  dequeueUpload: () => set((state) => ({ uploadQueue: state.uploadQueue.slice(1) })),
  setIsUploadLoading: (value: boolean) => set(() => ({ isUploadLoading: value })),
  setIndividualUploadProgress: (fileId: string, percent: number) =>
    set((state) => {
      const videoUploadList = state.videoUploadList.map((item: any) =>
        item.uid === fileId ? { ...item, rate: percent } : item,
      );
      return {
        videoUploadList,
      };
    }),
  setIndividualUploadStatus: (fileId: string, status: 'LOADING' | 'SUCCESS' | 'FAIL') =>
    set((state) => {
      const videoUploadList = state.videoUploadList.map((item: any) =>
        item.uid === fileId ? { ...item, status } : item,
      );
      return {
        videoUploadList,
      };
    }),

  setUploadVideoList: (video: TVideo) =>
    set((state) => ({
      uploadSuccessVideoList: [...state.uploadSuccessVideoList, video],
      uploadSuccessAt: Date.now(),
    })),
  allClearUploadData: () =>
    set(() => {
      return {
        videoUploadList: [],
        isUploadLoading: false,
        uploadQueue: [],
        uploadSuccessVideoList: [],
        aiClipBulkFileEmitter: 0,
      };
    }),
  deleteVideoList: (file: FileData) => {
    set((state) => {
      const filterVideoUploadList = state.videoUploadList.filter((item: FileData) => item.uid !== file.uid);
      return {
        videoUploadList: filterVideoUploadList,
        aiClipBulkFileEmitter: file.aiClipFileData
          ? Math.max(state.aiClipBulkFileEmitter - 1, 0)
          : state.aiClipBulkFileEmitter,
      };
    });
  },
}));
