import SideMenuItem from './side-menu-item';
import { useMemo } from 'react';
import getPhaseImageSrc from '@common/getPhaseImageSrc';
import { StyledSideMenu, StyledSideMenuItem } from './side-menu-styled';
import { t } from '@common/helper/languages';
import { AppstoreAddOutlined, CheckSquareOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { useAuthorityState } from '@context/authority/AuthorityContext';
import {
  AllCampaignIcon,
  BroadcastIcon,
  Mic2Icon,
  HostIcon,
  GoodsIcon,
  PopupIcon,
  SkinIcon,
  BrandIcon,
  MediaIcon,
  BlockUserIcon,
  BannedWordIcon,
  SellerIcon,
  SettingsIcon,
  CustomerManageIcon,
  DevGuideIcon,
  AdminGuideIcon,
  StarIcon,
  PluginIcon,
  DataInsightIcon,
  ShortformReviewIcon,
  SettingIcon as ShortformSettingIcon,
  SettingIcon as SDKLandingSettingIcon,
  ShortFormGroupIcon,
  ShortFormIcon,
  UsageIcon,
} from './icon';
import { ReactComponent as AiClipIcon } from '@assets/icon/Line__Systems--ai-clip.svg';
import { SideMenuLabel } from './side-menu-label';
import { getAdminGuidLinkUrl, getDeveloperGuideLinkUrl } from '@common/guide/link';
import CustomCollapse from '@styles/design-system/collapse/CustomCollapse';

interface ISideMenuProps {
  isMenuDrawer: boolean;
}

const SideMenu: React.FC<ISideMenuProps> = ({ isMenuDrawer }) => {
  const location = useLocation();
  const { myFunctions, isGlobalSuperAdmin, administrator, isEbayHost, customerLogo, language, myPrivileges } =
    useAuthorityState();

  const isShortformGroup = useMemo(() => {
    return myFunctions.SHORTFORM_GROUP;
  }, [myFunctions]);

  const isShortformCreator = useMemo(() => {
    return myFunctions.SHORTFORM_CREATOR;
  }, [myFunctions]);

  const isShortformReview = useMemo(() => {
    return myFunctions.SHORTFORM_REVIEW;
  }, [myFunctions]);

  const isUsageDashboard = useMemo(() => {
    return myFunctions.DATA_USAGE_DASHBOARD;
  }, [myFunctions]);

  const isAiClip = useMemo(() => myFunctions.AI_CLIP, [myFunctions]);

  // 11번가 검수자 only
  const isShortformReviewer = useMemo(() => !myPrivileges.isShortformManage, [myPrivileges]);

  const is11st = useMemo(() => {
    return myFunctions.STREET_11;
  }, [myFunctions]);

  const allowMenus = useMemo<string[]>(() => administrator?.authority.menus || [], [administrator]);

  const useShortformDataInsight = useMemo(() => {
    const isCustomerSuperAdmin = administrator?.authority?.roles[0] === 'CUSTOMER_SUPER_ADMIN';

    return isGlobalSuperAdmin || (myFunctions.SHORTFORM_DATAINSIGHT && isCustomerSuperAdmin);
  }, [administrator, myFunctions, isGlobalSuperAdmin]);

  const useShortFormMenu = useMemo(
    () => isGlobalSuperAdmin || allowMenus.includes('SHORT_FORMS'),
    [allowMenus, isGlobalSuperAdmin],
  );

  const phageImageSrc = useMemo(() => {
    return getPhaseImageSrc();
  }, []);

  return (
    <StyledSideMenu isMenuDrawer={isMenuDrawer} isEbay={isEbayHost}>
      <div className="logo-phase-wrapper">
        <div className="logo-wrapper">
          <Link to={'/campaigns2'}>
            <a>
              <div className="logo">
                <img className="img-logo" src={customerLogo} draggable="false" alt="service logo" />
              </div>
            </a>
          </Link>
        </div>
        {phageImageSrc && <img src={phageImageSrc} className="img-phase" draggable="false" alt="phase logo" />}
      </div>

      {/* 전체 캠페인, 전체 AI Clip */}
      {isGlobalSuperAdmin && (
        <>
          <SideMenuItem title="menu.campaign_monitor_list" icon={<AllCampaignIcon />} path="/campaigns/monitor" />
          <SideMenuItem title={'menu.medias_monitor'} icon={<MediaIcon />} path="/medias/monitor" />
          <SideMenuItem title={'menu.ai_clip_monitor'} icon={<AiClipIcon />} path="/ai-clip-monitor" />
        </>
      )}
      {(allowMenus.includes('CAMPAIGNS') || allowMenus.includes('PLUGIN')) && (
        <SideMenuLabel label={t('sidemenu.title.live')} />
      )}
      {/* 방송목록 */}
      {allowMenus.includes('CAMPAIGNS') && (
        <SideMenuItem
          title="menu.campaign"
          icon={<BroadcastIcon />}
          path={'/campaigns2'}
          relatedPath={['/campaigns2/:id', '/campaigns/:id(\\d+)', '/campaigns']}
          customStyle={{ marginBottom: '0' }}
        />
      )}

      {allowMenus.includes('PLUGIN') && (
        <CustomCollapse
          defaultActiveKey={location.pathname.includes('plugin') ? ['plugin'] : []}
          panelProps={{
            header: (
              <StyledSideMenuItem className="collapse-header">
                <div>
                  <PluginIcon />
                  {t('menu.plugin')}
                </div>
              </StyledSideMenuItem>
            ),
            key: 'plugin',
          }}
          panelChildren={
            <>
              <SideMenuItem title={t('menu.plugin.home')} path="/plugin/home" />
              <SideMenuItem title={t('menu.plugin.pip')} path="/plugin/pip" />
            </>
          }
        />
      )}

      {allowMenus.includes('HOSTS') && <SideMenuItem title="menu.hosts" icon={<HostIcon />} path="/hosts" />}
      {allowMenus.includes('POPUPS') && <SideMenuItem title="menu.popups" icon={<PopupIcon />} path="/popups" />}
      {(isGlobalSuperAdmin || myFunctions.LIVE_DATA_INSIGHT_V2) && (
        <SideMenuItem
          title="menu.shortform_data_insight"
          icon={<DataInsightIcon />}
          path="/campaigns/insight"
          badge="BETA"
        />
      )}
      {useShortFormMenu && (
        <>
          <SideMenuLabel label={t('sidemenu.title.shortform')} badge="NEW" />
          <SideMenuItem
            title={t('menu.shortform')}
            icon={<ShortFormIcon />}
            path="/short-forms"
            relatedPath={['/short-forms/new', '/short-forms/:id(\\d+)']}
          />
          {isShortformReview && (
            <>
              <SideMenuItem
                title={t('menu.shortform_inspection')}
                icon={<ShortformReviewIcon />}
                path="/short-forms/review"
              />
            </>
          )}
          {isShortformGroup && !isShortformReviewer && (
            <SideMenuItem
              title={t('menu.shortform_group')}
              icon={<ShortFormGroupIcon />}
              path="/short-forms/group"
              relatedPath={['/short-forms/group/new', '/short-forms/group/:id(\\d+)']}
            />
          )}

          {is11st && !isShortformReviewer && (
            <SideMenuItem
              title={t('menu.shortform_notice')}
              icon={<Mic2Icon />}
              path="/short-forms/notice"
              relatedPath={['/short-forms/notice/new', '/short-forms/notice/:id(\\d+)']}
            />
          )}

          {useShortformDataInsight && (
            <SideMenuItem
              title="menu.shortform_data_insight"
              icon={<DataInsightIcon />}
              path="/short-forms/insight"
              relatedPath={['/short-forms/insight', '/short-forms/ranking']}
              badge="BETA"
            />
          )}
        </>
      )}

      <SideMenuLabel label={t('sidemenu.title.asset')} />
      {(isGlobalSuperAdmin || isAiClip) && (
        <SideMenuItem title={'menu.ai_clip'} icon={<AiClipIcon />} path="/ai-clip" badge="BETA" />
      )}

      {((useShortFormMenu && !isShortformReviewer) || allowMenus.includes('MEDIAS')) && (
        <SideMenuItem title="menu.media" icon={<MediaIcon />} path="/medias" />
      )}

      {allowMenus.includes('GOODS') && <SideMenuItem title="menu.products" icon={<GoodsIcon />} path="/goods" />}
      {allowMenus.includes('SKINS') && <SideMenuItem title="menu.logos" icon={<SkinIcon />} path="/skins" />}

      {allowMenus.includes('BRANDS') && <SideMenuItem title="menu.brands" icon={<BrandIcon />} path="/brands" />}

      {(allowMenus.includes('SETTINGS') ||
        allowMenus.includes('SELLERS') ||
        allowMenus.includes('BANNED_USERS') ||
        allowMenus.includes('BANNED_WORDS') ||
        (useShortFormMenu && !isShortformReviewer)) && <SideMenuLabel label={t('sidemenu.title.setting')} />}
      {(isGlobalSuperAdmin || isUsageDashboard) && (
        <SideMenuItem
          title="page.data_usage_dashboard.summary_title_check_data_usage"
          icon={<UsageIcon />}
          path="/dataUsage"
        />
      )}

      {allowMenus.includes('SETTINGS') && (
        <SideMenuItem title="menu.settings" icon={<SettingsIcon />} path="/settings" />
      )}
      {allowMenus.includes('SELLERS') && (
        <SideMenuItem title="menu.seller" icon={<SellerIcon />} path="/sellers" relatedPath={['/sellers/:id']} />
      )}
      {isShortformCreator && !isShortformReviewer && (
        <SideMenuItem
          title="page.content_creator.content_creator"
          icon={<StarIcon />}
          path="/contentCreator"
          relatedPath={['/contentCreator/new', '/contentCreator/:id(\\d+)']}
        />
      )}

      {allowMenus.includes('BANNED_USERS') && (
        <SideMenuItem title="menu.banned_users" icon={<BlockUserIcon />} path="/penalties" />
      )}

      {allowMenus.includes('BANNED_WORDS') && (
        <CustomCollapse
          defaultActiveKey={location.pathname.includes('annedWords') ? ['bannedWords'] : []}
          panelProps={{
            header: (
              <StyledSideMenuItem className="collapse-header">
                <BannedWordIcon /> {t('menu.banned_words')}
              </StyledSideMenuItem>
            ),
            key: 'bannedWords',
          }}
          panelChildren={
            <>
              <SideMenuItem title="page.campaign_detail.basic.shoplive_banned_words" path="/bannedWords" />
              <SideMenuItem title="page.campaign_detail.basic.client_banned_words" path="/customerBannedWords" />
              <SideMenuItem title="page.campaign_detail.basic.host_banned_words" path="/hostBannedWords" />
            </>
          }
        />
      )}
      <SideMenuLabel label={t('sidemenu.title.guide')} />
      <StyledSideMenuItem>
        <a href={getAdminGuidLinkUrl(language)} target="_blank" rel="noopener noreferrer">
          <AdminGuideIcon />
          <div>{t('sidemenu.guide_admin')}</div>
        </a>
      </StyledSideMenuItem>
      <StyledSideMenuItem>
        <a href={getDeveloperGuideLinkUrl(language)} target="_blank" rel="noopener noreferrer">
          <DevGuideIcon />
          <div>{t('sidemenu.guide_developer')}</div>
        </a>
      </StyledSideMenuItem>

      {isGlobalSuperAdmin && <SideMenuLabel label={t('sidemenu.title.shoplive_only')} />}

      {allowMenus.includes('MANAGE_CUSTOMERS') && (
        <SideMenuItem
          title="menu.customer_management"
          icon={<CustomerManageIcon />}
          path="/customers"
          relatedPath={['/short-customers', '/customers/:id(\\d+)']}
        />
      )}

      {allowMenus.includes('MANAGE_SERVERS') && (
        <CustomCollapse
          defaultActiveKey={location.pathname.startsWith('/servers') ? ['servers'] : []}
          panelProps={{
            header: (
              <StyledSideMenuItem className="collapse-header">
                <SkinIcon /> {t('menu.server_management')}
              </StyledSideMenuItem>
            ),
            key: 'servers',
          }}
          panelChildren={
            <>
              <SideMenuItem title={t('menu.server_management_ec2s')} path="/servers/instances" />
              {/*<SideMenuItem title={t('menu.server_management_auto_scaling_groups')} path="/servers/auto-scaling-groups" />*/}
              <SideMenuItem title={t('menu.server_management_service')} path="/servers/service" />
              <SideMenuItem title={t('menu.server_management_stream_manager')} path="/servers/stream-manager" />
              <SideMenuItem title={t('menu.server_management_stream_worker')} path="/servers/stream-worker" />
              <SideMenuItem title={t('menu.server_management_media_convert')} path="/servers/media-convert" />
            </>
          }
        />
      )}

      {isGlobalSuperAdmin && (
        <>
          <SideMenuItem
            title={t('menu.shortform.settings')}
            path="/short-forms/settingUI"
            icon={<ShortformSettingIcon />}
          />

          {/* <SideMenuItem title={t('menu.plugin.json_editor')} path="/plugin/jsonEditor" icon={<PluginIcon />} /> */}
          <SideMenuItem title={t('menu.plugin.setting')} path="/plugin/settingUI" icon={<ShortformSettingIcon />} />
          <SideMenuItem
            title={t('menu.sdk-landing.settings')}
            path="/sdk-landing/settingUI"
            icon={<SDKLandingSettingIcon />}
          />
          <SideMenuItem title={'menu.ai_clip_prompt'} icon={<AiClipIcon />} path="/ai-clip-prompt" />

          <SideMenuItem
            customStyle={{
              lineHeight: '16px',
              fontSize: '12.5px',
            }}
            title="menu.campaign_event_trace"
            path="/campaigns/eventTrace"
            icon={<CheckSquareOutlined />}
          />

          <SideMenuItem
            customStyle={{
              lineHeight: '16px',
              fontSize: '12.5px',
            }}
            title="menu.shortform_event_trace"
            path="/short-forms/eventTrace"
            icon={<CheckSquareOutlined />}
          />
          {/* <SideMenuItem title="숏폼 JSON 에디터" path="/short-forms/json" icon={<PluginIcon />} /> */}
          {/* <SideMenuItem title={t('menu.live.settings')} path="/live/settingUI" icon={<ShortformSettingIcon />} /> */}

          <SideMenuItem title="Account Functions" icon={<ShortformSettingIcon />} path="/accountFunctions" />
        </>
      )}

      {isGlobalSuperAdmin && (
        <CustomCollapse
          defaultActiveKey={location.pathname.includes('plugin') ? ['plugin'] : []}
          panelProps={{
            header: (
              <StyledSideMenuItem className="collapse-header">
                <div>
                  <AppstoreAddOutlined />
                  {t('menu.setting.template')}
                </div>
              </StyledSideMenuItem>
            ),

            key: 'plugin',
          }}
          panelChildren={
            <>
              <SideMenuItem title={t('menu.setting.template.live')} path="/template/live" />
              <SideMenuItem title={t('menu.setting.template.plugin')} path="/template/plugin" />
              <SideMenuItem title={t('menu.setting.template.theme')} path="/template/theme" />
              <SideMenuItem title={t('menu.setting.template.shortform')} path="/template/shortforms" />
              <SideMenuItem title={t('menu.setting.template.sdk-landing')} path="/template/sdk-landing" />
            </>
          }
        />
      )}
    </StyledSideMenu>
  );
};

export default SideMenu;
