import { Select, SelectProps } from 'antd';
import styled from 'styled-components';
import { Icon } from '../icon/Icon';
import React from 'react';
import { BaseOptionType, DefaultOptionType, RefSelectProps } from 'antd/lib/select';
import CustomOption from './CustomOption';
type Props = {
  children?: React.ReactNode;
};
function CustomSelect({
  children,
  ...rest
}: Props &
  SelectProps<any, BaseOptionType | DefaultOptionType> & {
    ref?: React.Ref<RefSelectProps>;
  } & {
    SECRET_COMBOBOX_MODE_DO_NOT_USE?: string;
    Option?: typeof Option;
    OptGroup?: DefaultOptionType;
  }) {
  return (
    <S_Select
      suffixIcon={rest.suffixIcon ?? <Icon icon="arrowDownSLine" size={18} />}
      {...rest}
      dropdownStyle={{
        minWidth: rest.style?.minWidth,
      }}
      popupClassName="c-select"
    >
      {children}
    </S_Select>
  );
}

CustomSelect.Option = CustomOption;
export default CustomSelect;

const S_Select = styled(Select)`
  height: 36px !important;

  //default
  .ant-select-selector {
    background-color: ${({ theme }) => theme.newColor.White} !important;
    border-radius: 5px !important;
    height: 100% !important;
    display: flex;
    align-items: center;
    box-shadow: none !important;
  }

  .ant-select-selection-item {
    color: ${({ theme }) => theme.newColor.Black500} !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px;
    display: flex;
    align-items: center;
  }

  .ant-select-selection-search {
    height: 100% !important;

    input {
      height: 100% !important;
    }
  }

  .ant-select-arrow {
    background-color: ${({ theme }) => theme.newColor.White};
  }
  //focus
  .ant-select-focused {
    box-shadow: none !important;
  }
  //disabled
  &.ant-select-disabled {
    .ant-select-selector {
      background-color: ${({ theme }) => theme.newColor.Black50} !important;
    }
  }
`;
